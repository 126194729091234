export class ProjectItem {
  appName: string;
  appPhoto: string;
  description: string;
  type: string;
  phones: string;
  isLeft: boolean;
  category: number;
  appStoreUrl: string;
  playStoreUrl: string;

  constructor(appName: string, appPhoto: string, description: string, phones: string, type: string, isLeft: boolean, appStoreUrl: string, playStoreUrl: string, category?: number) {
    this.appName = appName;
    this.appPhoto = appPhoto;
    this.description = description;
    this.type = type;
    this.phones = phones;
    this.isLeft = isLeft;
    this.category = category;
    this.appStoreUrl = appStoreUrl;
    this.playStoreUrl = playStoreUrl;
  }
}

import { MenuItem } from "../../../model/entity/MenuItem";
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID } from "@angular/core";
import { DataUtils } from "../../../utility/DataUtils";
import { isPlatformBrowser } from "@angular/common";

@Component({
    selector: "toolbar",
    templateUrl: "./ToolbarComponent.html",
    styleUrls: ["./ToolbarComponent.scss"]
})
export class ToolbarComponent implements OnInit {

    @Output() onMenuButtonClicked = new EventEmitter();

    public toolbarStyle: any;
    public menuItems: MenuItem[] = DataUtils.getMenuItems();
    public languages: string[] = ["ENG", "TR"];
    public selectedMenuItem: MenuItem;
    public selectedLanguage;

    private currentPageIndex: number;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    ngOnInit() {
        this.selectedLanguage = this.languages[0];
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener("scroll", () => { this.onWindowScroll();});
            this.onCurrentPageChange(0);
        }
    }

    onMenuItemClick(item: MenuItem) {
        this.selectedMenuItem = item;
    }

    onMenuButtonClick() {
        this.onMenuButtonClicked.emit();
    }

    private onWindowScroll() {
        const elements: HTMLCollectionOf<Element> = document.getElementsByClassName("element-container");
        for (let i = 0; i < elements.length; i++) {
            const element: Element = elements[i];
            const top = -element.getBoundingClientRect().top;
            const height = element.getBoundingClientRect().height;
            if (top < height / 2) {
                if (this.currentPageIndex != i) {
                    this.currentPageIndex = i;
                    this.onCurrentPageChange(i);
                }
                break;
            }
        }
    }

    private onCurrentPageChange(index: number) {
        this.toolbarStyle = {
            "background-color": DataUtils.getPageBackgroundColors()[index],
            "background-image": "url(assets/img/toolbar_bg_" + (index + 1) + ".svg)",
            "background-size": "'cover'",
            "transition": "background 500ms"
        };
        this.setSelectedMenuItem(index);
    }

    private setSelectedMenuItem(pageIndex: number) {
        const projectCount = DataUtils.getBigProjects().length;
        if (pageIndex < 3) {
            this.selectedMenuItem = this.menuItems[pageIndex - 1];
        } else if (pageIndex < projectCount + 4) {
            this.selectedMenuItem = this.menuItems[2];
        } else {
            this.selectedMenuItem = this.menuItems[this.menuItems.length - 1];
        }
    }
}

import { Component, Input } from '@angular/core';
import { ProjectItem } from '../../../../model/entity/ProjectItem';

@Component({
  selector: 'project-item-info',
  templateUrl: './ProjectItemInfoComponent.html',
  styleUrls: ['./ProjectItemInfoComponent.scss']
})
export class ProjectItemInfoComponent {

  @Input() projectItem: ProjectItem;
}

import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { App } from './App';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './MaterialModule';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ShowcaseComponent } from '../home-page/showcase-component/ShowcaseComponent';
import { HomePage } from '../home-page/HomePage';
import { AboutComponent } from '../home-page/about-component/AboutComponent';
import { LeftProjectItemComponent } from '../home-page/projects-component/left-project-item-component/LeftProjectItemComponent';
import { RightProjectItemComponent } from '../home-page/projects-component/right-project-item-component/RightProjectItemComponent';
import { ToolbarComponent } from '../home-page/toolbar-component/ToolbarComponent';
import { SocialPlatformsComponent } from '../../view/component/social-platforms/SocialPlatformsComponent';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ServicesComponent } from '../home-page/services-component/ServicesComponent';
import { ServiceItemContainerComponent } from '../home-page/services-component/service-item-container-component/ServiceItemContainerComponent';
import { ProjectItemInfoComponent } from '../home-page/projects-component/project-item-info-component/ProjectItemInfoComponent';
import { AllProjectsComponent } from '../home-page/all-projects-component/AllProjectsComponent';
import { ReferencesComponent } from '../home-page/references-component/ReferencesComponent';
import { ContactComponent } from '../home-page/contact-component/ContactComponent';
import { ProjectsComponent } from '../home-page/projects-component/ProjectsComponent';
import { MenuComponent } from '../../view/component/menu-component/MenuComponent';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './AppRouting';

@NgModule({
  declarations: [
    App,
    HomePage,
    ShowcaseComponent,
    AboutComponent,
    ServicesComponent,
    ServiceItemContainerComponent,
    ProjectsComponent,
    LeftProjectItemComponent,
    RightProjectItemComponent,
    ProjectItemInfoComponent,
    AllProjectsComponent,
    ToolbarComponent,
    ReferencesComponent,
    ContactComponent,
    MenuComponent,
    SocialPlatformsComponent
  ],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    ScrollToModule.forRoot(),
    DeviceDetectorModule.forRoot()
  ],
  exports: [],
  providers: [],
})
export class AppModule {}

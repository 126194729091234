import { Component } from '@angular/core';

@Component({
  selector: 'references',
  templateUrl: './ReferencesComponent.html',
  styleUrls: ['./ReferencesComponent.scss']
})
export class ReferencesComponent {

}

import { AppModule } from './module/app/AppModule';
import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { App } from './module/app/App';

@NgModule({
  bootstrap: [App],
  imports: [
    BrowserModule.withServerTransition({appId: 'app-root'}),
    BrowserTransferStateModule,
    AppModule,
  ]
})
export class AppBrowserModule {}

import { Component } from '@angular/core';
import { ProjectItem } from '../../../model/entity/ProjectItem';
import { DataUtils } from '../../../utility/DataUtils';

@Component({
  selector: 'projects',
  templateUrl: './ProjectsComponent.html',
  styleUrls: ['./ProjectsComponent.scss']
})
export class ProjectsComponent {
  public projects: ProjectItem[] = DataUtils.getBigProjects();

}

import { Component } from '@angular/core';

@Component({
  selector: 'services',
  templateUrl: './ServicesComponent.html',
  styleUrls: ['./ServicesComponent.scss']
})
export class ServicesComponent {

}

import { RouterModule, Routes } from '@angular/router';
import { HomePage } from '../home-page/HomePage';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomePage}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}

import { Component, EventEmitter, Output } from '@angular/core';
import { DataUtils } from '../../../utility/DataUtils';

@Component({
  selector: 'menu',
  templateUrl: './MenuComponent.html',
  styleUrls: ['./MenuComponent.scss']
})
export class MenuComponent {
  @Output() onMenuVisibilityChanged = new EventEmitter();
  items = DataUtils.getMenuItems();

  public onMenuVisibilityChange() {
    this.onMenuVisibilityChanged.emit();
  }
}

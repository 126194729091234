import { Component } from '@angular/core';
import { ServiceItem } from '../../../../model/entity/ServiceItem';
import { DataUtils } from '../../../../utility/DataUtils';

@Component({
  selector: 'service-item-container',
  templateUrl: './ServiceItemContainerComponent.html',
  styleUrls: ['./ServiceItemContainerComponent.scss']
})
export class ServiceItemContainerComponent {
  public serviceItems: ServiceItem[] = DataUtils.getServiceItems();
}

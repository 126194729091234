export class AllProjectItem {
  appPhoto: string;
  appName: string;
  type: string;
  category: number;
  category2: number;

  constructor(appName: string, appPhoto: string, type: string, category: number, category2?: number) {
    this.appName = appName;
    this.appPhoto = appPhoto;
    this.type = type;
    this.category = category;
    this.category2 = category2;
  }
}

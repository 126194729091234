import { Component } from '@angular/core';

@Component({
  selector: 'about',
  templateUrl: './AboutComponent.html',
  styleUrls: ['./AboutComponent.scss']
})
export class AboutComponent {

}

import { ProjectItem } from "../model/entity/ProjectItem";
import { MenuItem } from "../model/entity/MenuItem";
import { ServiceItem } from "../model/entity/ServiceItem";
import { AllProjectItem } from "../model/entity/AllProjectItem";

export class DataUtils {

    static getPageBackgroundColors() {
        return [
            "#16181B",
            "#000000",
            "#1B2026",
            "#0157DE",
            "#5B86E5",
            "#8C0A90",
            "#2D2E57",
            "#38495C",
            "#212222",
            "#5853FF",
            "#3B4561",
            "#000000",
            "#112B19",
            "#1C1E22",
            "#1C1E22",
        ];
    }

    static getMenuItems() {
        return [
            new MenuItem("about", "HAKKIMIZDA"),
            new MenuItem("services", "HİZMETLER"),
            new MenuItem("projects", "PROJELER"),
            // new HeaderItem('references', 'REFERANSLAR'),
            new MenuItem("contact", "İLETİŞİM")
        ];
    }

    static getServiceItems() {
        return [
            new ServiceItem("../assets/img/service_item_container_component_mobile_service.png",
                "MOBİL UYGULAMA",
                "iOS ve Android platformlarına E-Ticaret, taşımacılık, sağlık, sosyal ağ, müzik, haber ve dergi gibi birçok kategoride 40'tan fazla uygulama geliştirip başarıyla teslim etmiş ekibimizle ihtiyacınızı ceplere optimum şekilde taşıyoruz."),
            new ServiceItem("../assets/img/service_item_container_component_web_service.png",
                "WEB UYGULAMALARI",
                "Problemlerinizi dijital ortamda çözmek istiyorsanız, büyük ölçekli web uygulama projeleri tecrübemizle kaliteli ve sağlam iş süreçlerini size de yaşatmamıza izin verin."),
            new ServiceItem("../assets/img/service_item_container_component_education_service.png",
                "EĞİTİM",
                "Bu zamana kadar birçok kurumsal firmaya eğitim vermiş uzman eğitmenlerimizle iOS, Android, Web uygulama geliştirme, Veritabanı ve Microsoft Office programları kullanımı tecrübelerimizi ekibinize aktarıp teknolojiyi etkin ve etkili kullanmanızı sağlıyoruz."),
            new ServiceItem("../assets/img/service_item_container_component_consultancy_service.png",
                "DANIŞMANLIK",
                "Bu zamana kadar birçok kurumsal firmaya eğitim vermiş uzman eğitmenlerimizle iOS, Android, Web uygulama geliştirme, Veritabanı ve Microsoft Office programları kullanımı tecrübelerimizi ekibinize aktarıp teknolojiyi etkin ve etkili kullanmanızı sağlıyoruz."),
            new ServiceItem("../assets/img/service_item_container_component_design_service.png",
                "TASARIM",
                "Mobil ve Web tabanlı uygulamalar için en güncel tasarım prensiplerini baz alarak kullanıcı arayüzü tasarlarız. Sadeliği ve kullanılabilirliği ile kullanıcı etkileşimini optimum seviyeye çıkaracak tasarımlar oluşturuyoruz.")];
    }

    static getBigProjects() {
        return [
            new ProjectItem("Hadi Bilgi Yarışması", "../assets/img/hadi_logo.png", "Hadi, canlı sunuculu ve gerçek para ödüllü bir bilgi yarışması oyunudur. Kullanıcılara 12 adet soru sorulur ve tüm soruları doğru cevaplayanlar toplam para ödülünü paylaşarak kazanırlar. Kullanıcı kazandığı parayı talep ettiğinde banka hesabına aktarılır. Kullanıcılar yarışırken aynı zamanda yorum kısmından birbirleri ile mesajlaşabilirler.", "../assets/img/hadi_bilgi_yarismasi_screenshot.png", "Oyun", true, "https://apps.apple.com/us/app/hadi-live-trivia-game-show/id1321315314", "https://play.google.com/store/apps/details?id=go.tv.hadi&hl=en"),
            new ProjectItem("Easy Route", "../assets/img/easy_route_trafik_navigasyon_logo.png", "Easy Route bir ücretsiz trafik asistanı ve navigasyon uygulamasıdır. Akıllı algoritmalar sayesinde istenilen saatteki trafik tahminini kullanıcıya göstererek kullanıcının en uygun saatte yola çıkmasını sağlar. Ayrıca araç bilgilerini giren kullanıcıların ücretli yol ve yakıt maliyetleri hesaplanır ve kullanıcıya gösterilir. Hızlı, ekonomik ve rahat olmak üzere 3 farklı rota seçeneği sunulur, kullanıcı kendisine en uygun rota tipini seçerek sesli ve yönlendirmeli navigasyonu başlatabilir.", "../assets/img/easy_route_screenshot.png", "Trafik ve Navigasyon", true, "https://apps.apple.com/tr/app/easyroute-trafik-navigasyon/id1271723884?l=tr", "https://play.google.com/store/apps/details?id=com.easyroute&hl=tr"),
            new ProjectItem("Health Ring", "../assets/img/health_ring_logo.png", "HealthRing, sizin ve sevdiğiniz insanların sağlığını görüntüleyip kontrol edebileceğiniz bir giyilebilir teknoloji platformudur. HealthRing bilekliği ile nabız, tansiyon, kandaki oksijen, adım sayısı, yakılan kalori miktarınızı ölçebilir ve bu ölçümleri kayıt altında tutabilirsiniz. Sevdiğiniz kişiler için ölçüm sınırları belirleyip kritik durumlarda sizi uyarmasını sağlayabilirsiniz.", "../assets/img/health_ring_screenshot.png", "Sağlık", true, "https://apps.apple.com/us/app/healthring-net/id1239781203", "https://play.google.com/store/apps/details?id=com.healthring&hl=en"),
            new ProjectItem("Chadio", "../assets/img/chadio_logo.png", "Chadio bir interaktif radyo uygulamasıdır. Chadio ile kullanıcılar tüm ülkelerden 100.000’in üzerinde radyo ve podcast dinleyebilir, diğer dinleyicilerle sohbet edebilir, radyoda çalan şarkının albüm bilgilerini ve şarkı sözlerini görüntüleyebilirler.", "../assets/img/chadio_screenshot.png", "Müzik", true, null, "https://play.google.com/store/apps/details?id=com.alberun.chadio&hl=en"),
            new ProjectItem("Almaya Geldim", "../assets/img/almaya_geldim_logo.png", "Almaya Geldim ilgili ürün ve hizmet kategorilerinde ihtiyaçları bulunan alıcıların kolay bir şekilde ilanlarını yayınladıkları, yayınlanan alım ilanlarına satıcıların kolay bir şekilde fiyat teklifi verdikleri ve bu sayede alıcılar ile satıcıları biraraya getirmeyi amaç edinmiş bir e-ticaret platformudur.", "../assets/img/almaya_geldim_screenshot.png", "E-Ticaret", true, null, "https://play.google.com/store/apps/details?id=com.almayageldim"),
            new ProjectItem("ProGO", "../assets/img/progo_logo.png", "Günaydın Group için geliştirdiğimiz ProGO uygulaması Türkiye genelinde şehir içi ve şehirler arası transfer ve ulaşım hizmetleri sağlayan UBER benzeri yasal dijital ulaşım asistanıdır. Kullanıcılar harita üzerinden kalkış-varış noktalarını belirleyip seyahat edecekleri tarih ve saati giriyorlar, ardından araç seçip kredi kartı ile ödeme yaptıktan sonra rezervasyon yapabiliyorlar. Ayrıca havalimanı karşılama hizmetleri, çocuk ve bebek koltuğu seçenekleri, araç içi ikramlar gibi ekstra özellikler seçilerek seyahatler özelleştirilebiliyor.", "../assets/img/progo_screenshot.png", "Seyahat", true, "https://apps.apple.com/us/app/progo/id1254995921", "https://play.google.com/store/apps/details?id=com.progo&hl=en"),
            new ProjectItem("MT Explorer", "../assets/img/mt_explorer_logo.png", "MT Explorer ile çevre analizlerinizi hızlı ve verimli bir şekilde yapabilir, lokasyona dair verilerinizi tek tuşla raporlayabilir, raporlarınızı ofisinize bağlı kalmadan bulunduğunuz her yerden anlık olarak görüntüleyebilirsiniz.", "../assets/img/mt_explorer_screenshot.png", "Harita Analizi ve Raporlama", true, "https://apps.apple.com/us/app/mt-explorer/id944970019", "https://play.google.com/store/apps/details?id=com.maptriks&hl=en"),
            new ProjectItem("HMA Academy", "../assets/img/hma_academy_logo.png", "HMA Academy; personal trainer’ların (kişisel antrenörlerin) spor salonunun üyelerini, ders kredilerini ve rezervasyonlarını kolayca yönetebilmesi amaçlanarak hem antrenör hem de üyeleri için geliştirilmiş bir mobil uygulamadır. Üyeler boş ders saatlerini görüntüleyebilir, günlük ya da ileri tarihli rezervasyon yapabilir, rezervasyonu sebep belirterek iptal edebilir ve kalan ders kredilerini görüntüleyebilirler.", "../assets/img/hma_academy_screenshot.png", "Spor", true, "https://apps.apple.com/us/app/hma-academy-personal-trainer/id1439692725", "https://play.google.com/store/apps/details?id=com.alberun.hma&hl=en"),
            new ProjectItem("Kral Müzik", "../assets/img/kral_muzik_logo.png", "Doğuş Yayın Grubu için geliştirdiğimiz Kral Müzik uygulaması ile kullanıcılar Doğuş Yayın Grubunun radyo istasyonlarını dinleyebiliyor, TV kanallarını izleyebiliyor, yayın akışlarını görüntüleyebiliyor ve ses, video, haber gibi tüm kayıtlı içeriklere ulaşabiliyor.", "../assets/img/kral_muzik_screenshot.png", "Müzik", true, null, null),
            new ProjectItem("MamoBall", "../assets/img/mamoball_logo.png", "MamoBall; realtime multiplayer 2D futbol oyunudur. Mekanik olarak air-hockey ve futbol karışımı bu oyunda oyuncular public ve private odalar kurup arkadaşlarıyla ve dünyanın dört bir yanından bağlanan diğer oyuncularla maç yapabilir, turnuva düzenleyebilir ve sohbet edebilirler.", "../assets/img/mamoball_screenshot.png", "Oyun", true, "https://apps.apple.com/tr/app/mamoball-online-2d-futbol/id1486178097", "https://play.google.com/store/apps/details?id=com.alberun.mamoball"),
        ];
    }

    static getAllProjects() {
        return [
            new AllProjectItem("Hadi Bilgi Yarışması", "../assets/img/hadi_logo.png", "Oyun", 1),
            new AllProjectItem("Easy Route Trafik Navigasyon", "../assets/img/easy_route_trafik_navigasyon_logo.png", "Harita", 2),
            new AllProjectItem("Health Ring", "../assets/img/health_ring_logo.png", "Sağlık", 4),
            new AllProjectItem("Chadio", "../assets/img/chadio_logo.png", "Müzik", 10),
            new AllProjectItem("Almaya Geldim", "../assets/img/almaya_geldim_logo.png", "E-Ticaret", 3),
            new AllProjectItem("ProGO", "../assets/img/progo_logo.png", "Seyahat", 5),
            new AllProjectItem("MT Explorer", "../assets/img/mt_explorer_logo.png", "Harita, Analiz ve Raporlama", 2, 6),
            new AllProjectItem("HMA Academy", "../assets/img/hma_academy_logo.png", "Spor", 7),
            new AllProjectItem("IGF", "../assets/img/igf_logo.png", "Araçlar", 9),
            new AllProjectItem("İkinci Yeni", "../assets/img/ikinci_yeni_logo.png", "E-Ticaret", 3),
            new AllProjectItem("Turkcell Keşif", "../assets/img/turkcell_kesif_logo.png", "Araçlar", 9),
            new AllProjectItem("Turkcell Sağlıkmetre", "../assets/img/turkcell_saglikmetre_logo.png", "Sağlık", 4),
            new AllProjectItem("Turkcell GollerCepte PisBurun Oyunu", "../assets/img/turkcell_gollercepte_pisburun_oyunu_logo.png", "Oyun", 1),
            new AllProjectItem("Kivo", "../assets/img/kivo_logo.png", "Harita", 2),
            new AllProjectItem("Nevo", "../assets/img/nevo_logo.png", "Harita", 2),
            new AllProjectItem("Ne Pişirsem", "../assets/img/ne_pisirsem_logo.png", "E-Ticaret", 3),
            new AllProjectItem("Perceev", "../assets/img/perceev_logo.png", "Spor", 7),
            new AllProjectItem("MamoBall", "../assets/img/mamoball_logo.png", "Oyun", 1),
            new AllProjectItem("HalkBank Borsa Oyunu", "../assets/img/halkbank_borsa_oyunu_logo.png", "Oyun", 1),
            new AllProjectItem("Couplinked", "../assets/img/couplinked_logo.png", "Sosyal Ağ", 8),
            new AllProjectItem("Kral Müzik", "../assets/img/kral_muzik_logo.png", "Müzik", 10),
            new AllProjectItem("Dentsply Sirona", "../assets/img/dentsply_sirona_logo.png", "Araçlar", 9),
            new AllProjectItem("Palm", "../assets/img/palm_logo.png", "Seyahat", 5),
        ];
    }

    static getAllProjectsCategoryItems() {
        return ["Hepsi", "Oyun", "Harita", "E-Ticaret", "Sağlık", "Seyahat", "Analiz ve Raporlama", "Spor", "Sosyal Ağ", "Araçlar", "Müzik"];
    }
}

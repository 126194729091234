import { Component, Input } from '@angular/core';
import { ProjectItem } from '../../../../model/entity/ProjectItem';

@Component({
  selector: 'right-project-item',
  templateUrl: './RightProjectItemComponent.html',
  styleUrls: ['./RightProjectItemComponent.scss']
})
export class RightProjectItemComponent {

  @Input() projectItem: ProjectItem;
}

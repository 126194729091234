import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

declare var ParticleSlider: any;

@Component({
    selector: "showcase",
    templateUrl: "./ShowcaseComponent.html",
    styleUrls: ["./ShowcaseComponent.scss"]
})
export class ShowcaseComponent implements OnInit {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.initParticleSlider();
        }
    }

    private initParticleSlider() {
        var ps = new ParticleSlider({
            ptlGap: 2,
            mouseForce: 1000,
            monochrome: true,
            color: "#1A73E8",
            ptlSize: 1,

        });

        var ptl = new ps.Particle(ps);
        ptl.ttl = 20;
    }
}

import { Component, Input } from '@angular/core';
import { ProjectItem } from '../../../../model/entity/ProjectItem';

@Component({
  selector: 'left-project-item',
  templateUrl: './LeftProjectItemComponent.html',
  styleUrls: ['./LeftProjectItemComponent.scss']
})
export class LeftProjectItemComponent {

  @Input() projectItem: ProjectItem;
}

import { Component } from '@angular/core';
import { DataUtils } from '../../../utility/DataUtils';
import { AllProjectItem } from '../../../model/entity/AllProjectItem';

@Component({
  selector: 'all-projects',
  templateUrl: './AllProjectsComponent.html',
  styleUrls: ['./AllProjectsComponent.scss']
})
export class AllProjectsComponent {

  public categoryItems: string[] = DataUtils.getAllProjectsCategoryItems();
  public allProjects: AllProjectItem[] = DataUtils.getAllProjects();
  public projects: AllProjectItem[] = DataUtils.getAllProjects();
  public selectedCategory: string = this.categoryItems[0];

  onCategoryClick(index: number) {
    this.selectedCategory = this.categoryItems[index];
    if (index == 0) {
      this.projects = this.allProjects;
    } else {
      const projects = [];
      for (let i = 0; i < this.allProjects.length; i++) {
        const project: AllProjectItem = this.allProjects[i];
        if (project.category == index || project.category2 == index) {
          projects.push(project);
        }
      }
      this.projects = projects;
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'social-platforms',
  templateUrl: './SocialPlatformsComponent.html',
  styleUrls: ['./SocialPlatformsComponent.scss']
})
export class SocialPlatformsComponent {

}

import { Component } from '@angular/core';

@Component({
  selector: 'contact',
  templateUrl: './ContactComponent.html',
  styleUrls: ['./ContactComponent.scss']
})

export class ContactComponent {

}

import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { DataUtils } from "../../utility/DataUtils";
import { isPlatformBrowser } from "@angular/common";

@Component({
    templateUrl: "./HomePage.html",
    styleUrls: ["./HomePage.scss"]
})
export class HomePage implements OnInit {
    public isMenuVisible: boolean = false;

    private currentScrolledPageIndex: number = 0;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener("scroll", () => { this.onWindowScroll();});
            this.onCurrentPageChange(0);
        }
    }

    public changeMenuVisibility() {
        this.isMenuVisible = !this.isMenuVisible;
    }

    private onWindowScroll() {
        const elements: HTMLCollectionOf<Element> = document.getElementsByClassName("element-container");
        for (let i = 0; i < elements.length; i++) {
            const element: Element = elements[i];
            const top = -element.getBoundingClientRect().top;
            const height = element.getBoundingClientRect().height;
            if (top < height / 2) {
                if (this.currentScrolledPageIndex != i) {
                    this.currentScrolledPageIndex = i;
                    this.onCurrentPageChange(i);
                }
                break;
            }
        }
    }

    private onCurrentPageChange(index: number) {
        const bodyStyle = document.getElementsByTagName("body")[0].style;
        let backgroundImage = "url(assets/img/page_bg_" + (index + 1);
        if (index < 3 || index == 14) {
            backgroundImage += ".jpg";
        } else {
            backgroundImage += ".svg";
        }
        bodyStyle.backgroundColor = DataUtils.getPageBackgroundColors()[index];
        bodyStyle.backgroundImage = backgroundImage;
        bodyStyle.backgroundRepeat = "no-repeat";
        bodyStyle.backgroundSize = "cover";
        bodyStyle.backgroundAttachment = "fixed";
    }
}

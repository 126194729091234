export class ServiceItem {
  image: string;
  title: string;
  content: string;

  constructor(image: string, title: string, content: string) {
    this.image = image;
    this.title = title;
    this.content = content;
  }
}
